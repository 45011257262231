<template>
  <section-item height="600px" :style="{backgroundImage: 'url(' + require('../../assets/banner/product-banner.png') + ')' }">
    <div class="text-box">
      <span class="title">{{ list.name }}</span>
      <div class="tag-box">
        <div class="tag-item" v-for="(item, index) in list.tags" :key="index">
          <span>{{ item }}</span>
        </div>
      </div>
      <span class="content">{{ list.content }}</span>
    </div>
  </section-item>
</template>

<script>
export default {
  name: "product-banner",
  props: {
    list: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {};
  },
  methods: {},
  created() {
  },
};
</script>

<style lang="scss" scoped>
.text-box {
  width: 712px;
}
.title{
  font-size: 60px;
  font-family: lianmengqiyilushuaizhengruiheiti;
  font-weight: 400;
  color: #FFFFFF;
}
.tag-box {
  display: flex;
  align-items: center;
  margin: 23px 0;

  &:last-child{
    margin-right: 0px;
  }

  .tag-item{
    margin-right: 20px;
    text-align: center;
    padding: 10px 26px;
    font-size: 30px;
    font-family: SimSun;
    font-weight: 400;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
  }
}
.content{
  font-size: 24px;
  font-family: SimSun;
  font-weight: 400;
  color: #FFFFFF;
}
</style>

