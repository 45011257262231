<template>
  <section-item title="核心功能" subtitle="Core Functions" height="961px" bgColor="#f3f3f7">
    <el-carousel height="600px" direction="vertical" :interval="5000">
      <el-carousel-item v-for="(item,index) in display" :key="index" style="text-align: center">
        <div class="content">
          <el-image class="image" :src="item.img" style="height: 100%"/>
          <div class="right-box">
            <div style="width: 20px; height: 6px; background-color: #FE5252"></div>
            <el-divider></el-divider>
            <span class="title">{{item.title}}</span>
            <span class="subtitle" v-if="(typeof item.subtitle) == 'string'">{{item.subtitle}}</span>
            <div class="subtitle" v-if="Array.isArray(item.subtitle)">
              <div v-for="(textItem, index) in item.subtitle" :key="index">{{ textItem.text }}</div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </section-item>
</template>

<script>
export default {
  name: "product-detail",
  props: {
    display: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content{
  display: flex;
  align-items: center;

  .image{
    width: 880px;
  }
}
.right-box{
  background-color: #fff;
  width: 400px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 40px 0 40px;

  .title{
    font-size: 34px;
    line-height: 34px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
    margin: 24px 0 24px 0;
  }

  .subtitle{
    //width: 238px;
    text-align: left;
    font-size: 24px;
    line-height: 34px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
}
::v-deep .is-active .el-carousel__button {
  background-color: #FE5252;
}
::v-deep .el-carousel__button {
  background-color: #EAEAEA;
  width: 6px;
  height: 40px;
  border-radius: 3px;
}
::v-deep .el-divider--horizontal{
  margin: 8px 0 0 0;
}
</style>

