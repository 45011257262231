<template>
  <div>
    <div v-for="(item,index) in list" :key="index">
      <template v-if="item.name===id">
        <product-banner :list="item"></product-banner>
        <product-detail :display="item.display"></product-detail>
      </template>
    </div>
  </div>
</template>

<script>
import ProductBanner from './product-banner'
import ProductDetail from './product-detail'

export default {
  components: {
    ProductBanner,
    ProductDetail
  },
  mounted() {
    this.id = this.$route.params.id
  },
  data() {
    return {
      id: '',
      list: [{
        name: '小沐商城',
        tags: ['多端可用', '运行稳定', '商品丰富'],
        content: '一个专注于手机配件批发的平台，为数万家手机专卖店、维修反提供手机配件的批发供应与服务',
        display: [{
          img: require('@/assets/product/product-swipe8.png'),
          title: '分类商品',
          subtitle: '多种品类分别展示，快速查找'
        }, {
          img: require('@/assets/product/product-swipe1.png'),
          title: '购物车',
          subtitle: '让购物、支付更方便，更实惠'
        }, {
          img: require('@/assets/product/product-swipe2.png'),
          title: '历史交易记录',
          subtitle: '随时随地查询历史交易状态'
        }]
      }, {
        name: '特价购物',
        tags: ['高性价比', '两种购买方式'],
        content: '功能机应用购物商城，全新体验！',
        display: [{
          img: require('@/assets/product/product-swipe25.png'),
          title: '商品购买',
          subtitle: '支持系统下单、电话下单'
        }, {
          img: require('@/assets/product/product-swipe26.png'),
          title: '产品浏览',
          subtitle: '翻阅商品享受智能机页面网速'
        }]
      }, {
        name: '跨境电商',
        tags: ['多语言', '多地区', '品类丰富'],
        content: '汇聚全球好物，时刻拥有精致生活。跨境直邮，与海外买家同步选购，无国界分享购物体验。',
        display: [{
          img: require('@/assets/product/product-swipe27.png'),
          title: '国家选择',
          subtitle: '根据国家选择展示可销售商品'
        }, {
          img: require('@/assets/product/product-swipe28.png'),
          title: '个人信息',
          subtitle: '个人订单、信息查看管理'
        }, {
          img: require('@/assets/product/product-swipe29.png'),
          title: '搜索',
          subtitle: '辅助用户快速查找商品'
        }]
      }, {
        name: '微聊定位',
        tags: ['智能机互通', '查看位置', '支持语音'],
        content: '一款专为家长关注未成年人、老年人户外安全设计的软件',
        display: [{
          img: require('@/assets/product/product-swipe3.png'),
          title: '扫码绑定',
          subtitle: '与智能机扫码绑定使用'
        }, {
          img: require('@/assets/product/product-swipe4.png'),
          title: '位置信息',
          subtitle: '实时查看人员位置信息'
        }]
      }, {
        name: '视频聊天',
        tags: ['面对面沟通', '人性化界面'],
        content: '告别普通的电话沟通，拒绝昂贵的长途漫游，4G网络下让真人视频沟通在功能机上实现。',
        display: [{
          img: require('@/assets/product/product-swipe30.png'),
          title: '视频通话',
          subtitle: '与亲人仿佛面对面的沟通'
        }, {
          img: require('@/assets/product/product-swipe31.png'),
          title: '语音通话',
          subtitle: '告别文字聊天，拉近亲密距离'
        }]
      }, {
        name: '微视频',
        tags: ['可互动', '技能学习'],
        content: '功能机上一个帮助用户表达自己，了解新闻学习技能的短视频平台！',
        display: [{
          img: require('@/assets/product/product-swipe9.png'),
          title: '点赞、收藏',
          subtitle: '针对感兴趣的内容进行点赞收藏，方便二次查看'
        }, {
          img: require('@/assets/product/product-swipe10.png'),
          title: '横竖屏',
          subtitle: '根据视频类型切换合适的播放模式，享受微视频带来的乐趣'
        }, {
          img: require('@/assets/product/product-swipe11.png'),
          title: '发布评论',
          subtitle: '针对视频内容表达自己的看法，让更多人看到'
        },]
      }, {
        name: '小沐对讲',
        tags: ['实时对讲', '两种聊天方式'],
        content: '一款可实现老年机与智能机聊天的应用，打破原本只存在于智能机之前的聊天模式。',
        display: [{
          img: require('@/assets/product/product-swipe32.png'),
          title: '实时对讲',
          subtitle: '支持一对一、一对多实时语音对讲，消息实时传达'
        }, {
          img: require('@/assets/product/product-swipe33.png'),
          title: '添加好友',
          subtitle: '支持添加好友（功能机、智能机）'
        }, {
          img: require('@/assets/product/product-swipe34.png'),
          title: '聊天',
          subtitle: '支持文字、语音两种聊天方式'
        }]
      }, {
        name: '沐宝卫士',
        tags: ['安全守护', '实时沟通', '三端管控'],
        content: '一款依靠强大的技术，让家长实现守护孩子的安全成长，远程管理孩子的手表的应用。',
        display: [{
          img: require('@/assets/product/product-swipe43.png'),
          title: '智能机手表',
          subtitle: [
            {text: '实时定位：多重定位技术支持家长实时查看孩子的定位信息'},
            {text: '微聊：手表上轻松实现社交，支持加好友、发语音、表情等'},
            {text: '视频通话：一键拉近距离，告别普通电话'},
            {text: '应用商城：手表专属应用商城，海量资源可供下载'},
            {text: '个性商城：为手表提供多种个性资源，展现个性时尚'},
            {text: '风格切换：多种风格随心挑，玩转不一样的掌上手表'}
          ]
        }, {
          img: require('@/assets/product/product-swipe44.png'),
          title: '功能机手表',
          subtitle: [
            {text: '实时定位：多重定位技术支持家长实时查看孩子的定位信息'},
            {text: '微聊：提供多种交流方式，支持语音、群组、表情等'},
            {text: '视频通话：4G网络下与家长高清通话，简单易上手'},
            {text: '一键求救：紧急情况下一键拨打求救电话'},
            {text: '手表管理：涵盖课程管理、上课禁用、拦截陌生来电等功能'},
            {text: '应用商城：为儿童打造的教育娱乐软件，让孩子劳逸结合'}
          ]
        }, {
          img: require('@/assets/product/product-swipe45.png'),
          title: '手机端',
          subtitle: [
            {text: '查看定位：高精准定位服务，孩子外出更安心'},
            {text: '电子围栏：多场景守护孩子安全'},
            {text: '应用监管：禁下载严管控，全面监管手表应用'},
            {text: '上课禁用：一键解决家长困扰'},
            {text: '紧急求救：设定SOS，孩子安全有保障'},
            {text: '海量资源：听优质书籍、海量咨询，课余时光聆听世界'}
          ]
        }, {
          img: require('@/assets/product/product-swipe46.png'),
          title: '管理平台',
          subtitle: [
            {text: '用户管理：数据随心看，实用又安全'},
            {text: '问题反馈：问题随时跟，便捷又高效'},
            {text: '套餐设置：价格随心改，简便又快捷'},
            {text: '资源部署：资源动态配，方便易维护'}
          ]
        }, {
          img: require('@/assets/product/product-swipe47.png'),
          title: '整体方案提供',
          subtitle: '提供后台+APP+手表应用+内容扩展+FAE技术支持的整体解决方案，为硬件方案商和品牌商提供交钥匙服务'
        }]
      }, {
        name: '学生卡',
        tags: ['定位', '无感考勤', '三端管控'],
        content: '代替手机入校，通过智能学生卡的记录、App的监测、平台的运维等多个环节的配合来打造智慧型平安校园，为孩子生活的安全和便捷助力，为家庭保驾护航',
        display: [{
          img: require('@/assets/product/product-swipe5.png'),
          title: '硬件',
          subtitle: '实时定位、语音通话、一键求救、安全围栏、超长待机、无感考勤'
        }, {
          img: require('@/assets/product/product-swipe6.png'),
          title: '手机端',
          subtitle: '查看定位、课程表、安全提示、通讯录白名单、消息通知、设备管理'
        }, {
          img: require('@/assets/product/product-swipe7.png'),
          title: '管理平台',
          subtitle: '用户管理、考勤管理、运营维护、权限管理、设备管理、校务管理'
        }]
      }, {
        name: '儿童智能手表',
        tags: ['定位', '语音通话', '三端管控'],
        content: '通过管理APP实现与智能手表的连接使用，APP可以对手表设置不同的表盘以及控制其他功能使用。',
        display: [{
          img: require('@/assets/product/product-swipe15.png'),
          title: '硬件',
          subtitle: '积分充值、应用商城、表盘设置、应用下载管理、应用中心'
        }, {
          img: require('@/assets/product/product-swipe16.png'),
          title: '手机端',
          subtitle: '绑定手表、应用管理、定时锁定、积分充值、数据统计、信息设置'
        }, {
          img: require('@/assets/product/product-swipe17.png'),
          title: '管理平台',
          subtitle: '充值套餐设置、统计订单、应用部署'
        },]
      }, {
        name: '蓝牙耳机',
        tags: ['蓝牙连接', '降噪', '双端管控'],
        content: '基于无线蓝牙技术，通过与蓝牙通讯设备(例如APP)的连接，实现解放双手，语音控制接打电话、接发短信和控制某些智能电器等目的一种穿戴式智能设备，从而使人们的生活更加美好舒适。',
        display: [{
          img: require('@/assets/product/product-swipe41.png'),
          title: '硬件',
          subtitle: '语言播报、语音通话、允许定位、智能降噪'
        }, {
          img: require('@/assets/product/product-swipe42.png'),
          title: '手机端',
          subtitle: '蓝牙连接、电量查看、智能管理'
        }]
      }, {
        name: 'QQ小程序游戏',
        tags: ['无需下载', '轻松娱乐', '碎片时间'],
        content: '基于QQ小程序开发的手游，玩法多样有趣，闲暇时光轻松一下！',
        display: [{
          img: require('@/assets/product/product-swipe12.png'),
          title: '实时匹配',
          subtitle: '在线玩家随机匹配，挑战不同实力的对手'
        }, {
          img: require('@/assets/product/product-swipe13.png'),
          title: '道具加持',
          subtitle: '购买道具如虎添翼，增加游戏趣味性'
        }, {
          img: require('@/assets/product/product-swipe14.png'),
          title: '装备加持',
          subtitle: '购买升级装备，提升自我的战力'
        },]
      }, {
        name: '电视机游戏',
        tags: ['大屏体验', '轻松娱乐', '沉浸式'],
        content: '基于智能电视机开发的游戏，沉浸式大屏体验，休闲时间放松身心，锻炼锻炼思维和反应能力。',
        display: [{
          img: require('@/assets/product/product-swipe18.png'),
          title: '闯关',
          subtitle: '限定时间内完成关卡任务'
        }, {
          img: require('@/assets/product/product-swipe19.png'),
          title: '道具加持',
          subtitle: '购买道具如虎添翼，增加游戏趣味性'
        }, {
          img: require('@/assets/product/product-swipe20.png'),
          title: '手柄适配',
          subtitle: '适配手柄，提高游戏体验感'
        },]
      }, {
        name: '喜马拉雅',
        tags: ['有声图书馆', '给你想要'],
        content: '碎片时间变黄金，资讯知识随你动，段子减压少烦恼，音乐伴你好入眠',
        display: [{
          img: require('@/assets/product/product-swipe21.png'),
          title: '多种分类',
          subtitle: '多种类目，想听啥都有'
        }, {
          img: require('@/assets/product/product-swipe22.png'),
          title: '我的收藏',
          subtitle: '感兴趣内容一键收藏，方便查看'
        }]
      }, {
        name: '贝乐虎',
        tags: ['知名IP', '成长需求'],
        content: '海量故事资源，满足孩子成长需求',
        display: [{
          img: require('@/assets/product/product-swipe23.png'),
          title: '定时关闭',
          subtitle: '自由选择应用关闭时间'
        }, {
          img: require('@/assets/product/product-swipe24.png'),
          title: '搜索',
          subtitle: '辅助用户快速查找需求资源'
        }]
      }, {
        name: '小沐助教',
        tags: ['精准教学', '降低管理成本', '专业教务管理'],
        content: '专业的数字化管理系统。立足于教育培训学校办学场景，全方面运营管理解决方案，帮助培训学校降低管理成本，实现管理数字化升级。',
        display: [{
          img: require('@/assets/product/product-swipe35.png'),
          title: '智能排课',
          subtitle: '排课方式多样化，自动检测排课冲突，让排课更便捷'
        }, {
          img: require('@/assets/product/product-swipe36.png'),
          title: '财务报表',
          subtitle: '支持财务数据分析，机构收入与支出一目了然'
        }, {
          img: require('@/assets/product/product-swipe37.png'),
          title: '学情反馈',
          subtitle: '向家长同步学生课堂表现及作业情况'
        }]
      }, {
        name: '小沐导学',
        tags: ['错题解析', '听写训练', '成绩分析'],
        content: '顺应在线教育的潮流，结合主流AI技术，根据不同学校、角色提供针对性服务。',
        display: [{
          img: require('@/assets/product/product-swipe38.png'),
          title: '搜题',
          subtitle: '支持拍照、文字搜题'
        }, {
          img: require('@/assets/product/product-swipe39.png'),
          title: '批改作业',
          subtitle: '支持在线提交家庭作业'
        }, {
          img: require('@/assets/product/product-swipe40.png'),
          title: '错题本',
          subtitle: '错题记录练习'
        }]
      },
      ]
    }
  }
}


</script>